@use '../../styles/mixins' as mixins;

.statistics-block {

	@include mixins.sectionStyles;
	
	.bnpp-container {
		@include mixins.grid(400px);

		&>div>div.block-editor-block-list__layout {
			@include mixins.grid(400px);
		}

	}

	&.contained {

		.bnpp-container {
			padding: var(--wp--preset--spacing--60);
		}

	}

	&.single {
		.bnpp-container {
			@include mixins.grid(100%);
		}

		.statistics-block__content {
			text-align: center;
		}

		.statistics-block__statistics {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 2em;

			.bnpp-statistics-module {
				flex: 0 1 275px;
			}
		}
	}

	h2 {
		padding-top: var(--wp--preset--spacing--50);
	}

	.statistics-block__statistics {
		@include mixins.grid(200px);

		.bnpp-statistics-module {
			background-color: var(--wp--preset--color--bnpp-green-tint-10);
			padding: var(--wp--preset--spacing--60);
			height: auto;
			
			h3 {
				color: var(--wp--preset--color--bnpp-green);
			}

			p {
				margin: 0;
			}

		}
	}

	&.dark {
        .statistics-block__statistics {
            .bnpp-statistics-module {
				background-color: #0A352E;
				padding: var(--wp--preset--spacing--60);
				height: fit-content;
				
				h3 {
					color: var(--wp--preset--color--white);
				}
		
				p {
					color: #7FD4B5;
				}
			}
        }
    }

}